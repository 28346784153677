import React from 'react'
import Layout from './../components/layout'
import SEO from './../components/seo'

const AboutPage = function about() {
    return (
        <Layout>
            <SEO title='Services' />
            <h1>Services</h1>
            <p>Delectus officia explicabo et similique ut. Dolor facere tenetur. Ut illum vero quae. Est consectetur rerum rerum facere. Quia ad consequatur quaerat aut sit labore velit.

Nulla assumenda atque qui harum est. Aut sint iste cupiditate. Expedita omnis modi.

Quas vitae eos at quis ut quo est non voluptatem. Est impedit ea est inventore. Eos ad odit culpa quia. Esse cupiditate aut totam similique aut aut tempore sint. Est eos consequatur architecto nihil fugiat ut dolores.</p>
        </Layout>
    )
}

export default AboutPage
